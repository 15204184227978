import React, { useState } from "react"
import axios from "axios"
import {
  Box,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Checkbox,
} from "@theme-ui/components"
import { i18nContext } from "../context/i18nContext"

const NewsletterForm = ({ title }) => {
  //   console.log(record)
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const addSubscriber = async event => {
    event.preventDefault()
    const data = formData
    setLoading(true)
    // console.log(data)
    axios
      .post("/.netlify/functions/addSubscriber", data)
      .then(function (response) {
        setSuccess(true)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
      })
  }
  return (
    <i18nContext.Consumer>
      {t => (
        <Grid
          columns={[1, 1, 2, "2fr 3fr 1fr"]}
          gap={[32, 64]}
          sx={{ mt: [4, 6], mb: [4, 6] }}
        >
          <Box>
            <Box
              dangerouslySetInnerHTML={{ __html: title }}
              sx={{ h2: { color: "dark", lineHeight: 1.3, mt: 0 } }}
            />
          </Box>
          <Box>
            <Box as="form" onSubmit={addSubscriber}>
              <Label htmlFor="firstName">{t.firstName}</Label>
              <Input
                onChange={e =>
                  setFormData(prevState => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
                name="firstName"
                placeholder={t.firstName}
                required
              />
              <Label htmlFor="lastName">{t.lastName}</Label>
              <Input
                onChange={e =>
                  setFormData(prevState => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
                name="lastName"
                placeholder={t.lastName}
                required
              />
              <Label htmlFor="email">{t.email}</Label>
              <Input
                onChange={e =>
                  setFormData(prevState => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                name="email"
                type="email"
                placeholder={t.email}
                required
              />
              <Label mb={3} variant="checkboxLabel">
                <Checkbox required />
                {t.employee}
              </Label>
              <Label mb={3} variant="checkboxLabel">
                <Checkbox required />
                <Box
                  dangerouslySetInnerHTML={{ __html: t.newsletterDisclaimer }}
                />
              </Label>
              <Button
                type="submit"
                sx={{ mt: [2, 3] }}
                disabled={success || loading}
                variant={success ? "success" : "primary"}
              >
                <Flex
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {loading
                    ? t.loading
                    : success
                    ? t.success
                    : t.subscribeButton}
                </Flex>
              </Button>
            </Box>
          </Box>
        </Grid>
      )}
    </i18nContext.Consumer>
  )
}

export default NewsletterForm
