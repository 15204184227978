import { graphql } from "gatsby"
import * as React from "react"
import { Container, Heading } from "theme-ui"
import { languages, i18nContext } from "../context/i18nContext"

import Layout from "../components/layout"
import NewsletterForm from "../components/newsletterForm"
import SEO from "../components/seo"

const Page = ({ data: { page } }) => {
  // console.log(page)
  return (
    <i18nContext.Provider value={languages[page.locale]}>
      <Layout locale={page.locale}>
        <SEO title="Newsletter" />
        <Container>
          <Heading
            as="h1"
            variant="h1"
            sx={{
              fontSize: 6,
              fontWeight: 400,
              color: "dark",
              lineHeight: "heading",
            }}
          >
            {page.title}
          </Heading>
          <NewsletterForm title={page.content[0].title} />
        </Container>
      </Layout>
    </i18nContext.Provider>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      title
      locale
      slug
      content {
        id
        title
      }
    }
  }
`
